/**
 * Created by vaibhav on 31/3/18
 */
import React from "react"

const NotFoundPage = () => (
  <div>
    <section className="hero is-primary is-bold is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section is-centered">
                <h1 className="title">404: NOT FOUND</h1>
                <h2 className="subtitle">
                  You just hit a route that doesn&#39;t exist... the sadness.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default NotFoundPage
